import React, { useEffect, useState } from "react";

import { Alert, Spinner, Button, Form } from "react-bootstrap";
const API_BASE_URL = require("../api-home.js").api;

const LocateCoordsForm = ({ onSearch }) => {
  const [lon, setLon] = useState("");
  const [lat, setLat] = useState("");
  const [locateCoords, setLocateCoords] = useState(false);

  const [geoError, setGeoError] = useState("");
  const [errorAlert, seterrorAlert] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState();

  const [isGeoLocating, setIsGeoLocating] = useState(false);
  const [geoSpinner, setGeoSpinner] = useState();

  useEffect(() => {
    if (locateCoords) {
      setIsLoading(true);

      const fetchURL =
        API_BASE_URL + "/encuentra/coordenadascp?lon=" + lon + "&lat=" + lat;
      fetch(fetchURL)
        .then((res) => res.json())
        .then((searchResults) => {
          if (searchResults.length < 1) {
            setGeoError("No se encontraron registros con esas coordenadas");
            return { found: false };
          }
          setGeoError("");
          return { searchResults, lat, lon, isFound: true };
        })
        .then((output) => {
          setLocateCoords(false);
          setIsLoading(false);
          return output;
        })
        .then((output) => {
          onSearch(output);
        });
    }
  }, [locateCoords, lat, lon, onSearch]);

  useEffect(() => {
    if (isGeoLocating) {
      setGeoSpinner(
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    } else {
      setGeoSpinner();
    }
  }, [isGeoLocating]);

  useEffect(() => {
    if (isLoading) {
      setLoadingSpinner(
        <Spinner animation="border" role="status" size="sm">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    } else {
      setLoadingSpinner();
    }
  }, [isLoading]);

  const submitHandler = (e) => {
    e.preventDefault();
    setLocateCoords(true);
  };

  const lonFormHandler = (e) => {
    setLon(e.target.value);
  };

  const latFormHandler = (e) => {
    setLat(e.target.value);
  };

  const mylocationClickHandler = () => {
    setIsGeoLocating(true);
    setGeoError("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (p) => {
          setLat(p.coords.latitude);
          setLon(p.coords.longitude);
          setIsGeoLocating(false);
        },
        () => {
          setIsGeoLocating(false);
          setGeoError(
            "Error no se pudo obtener ubicación de tu dispositivo o navegador."
          );
        }
      );
    } else {
      setGeoError(
        "Error no se pudo obtener ubicación de tu dispositivo o navegador."
      );
    }
  };

  useEffect(() => {
    if (geoError !== "") {
      seterrorAlert(<Alert variant="danger">{geoError}</Alert>);
    } else {
      seterrorAlert();
    }
  }, [geoError]);

  return (
    <>
      <Form>
        {errorAlert}
        <Form.Group>
          <Form.Label>Latitud</Form.Label>
          <Form.Control
            value={lat}
            placeholder="e.g. 19.43271"
            onChange={latFormHandler}
          />
          <Form.Text className="text-muted">
            Latitud en grados decimales
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Form.Label>Longitud</Form.Label>
          <Form.Control
            value={lon}
            type="text"
            placeholder="e.g. -99.13337"
            onChange={lonFormHandler}
          />
          <Form.Text className="text-muted">
            Longitud en grados decimales
          </Form.Text>
        </Form.Group>
        <Form.Group>
          <Button
            className="mylocation_button"
            variant="info"
            onClick={mylocationClickHandler}
          >
            Usar mi ubicación {geoSpinner}
          </Button>{" "}
          <Button variant="primary" type="submit" onClick={submitHandler}>
            Buscar CP {loadingSpinner}
          </Button>
        </Form.Group>
      </Form>
    </>
  );
};

export default LocateCoordsForm;
