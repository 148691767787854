import BreadcrumbExplore from "../BreadcrumbExplore";
import ZipCard from "../ZipCard/ZipCard";
import ZipMap from "../ZipMap";
import LinkGrid from "../LinkGrid/LinkGrid";

import "./ZipView.css";

const ZipView = ( { featuredZipcode, zipcodesGeoJSON, lat, lon, onZipSelect, radius, radiusCenter } ) => {
  
  return (
    <>
      <div className="ZipView__breadcrumb">
        {featuredZipcode && (
          <BreadcrumbExplore
            estado={featuredZipcode.Estado}
            municipio={featuredZipcode.Municipio}
            cp_or_geo={"cp"}
            zipcode={featuredZipcode.codigo_postal}
          />
        )}
      </div>

      {featuredZipcode && <ZipCard zipObj={featuredZipcode} />}

      {zipcodesGeoJSON && (
            <ZipMap
              data={zipcodesGeoJSON}
              featuredZip={featuredZipcode.codigo_postal}
              marker={lat && lon ? [lat, lon] : undefined}
              onPolygonClick={onZipSelect}
              onChangeBounds={(e) => {console.log(e)}}
              circle={{center : radiusCenter, radius : radius}}
            />
          )
      }

      { zipcodesGeoJSON && 
          <LinkGrid
            header="Códigos postales vecinos"
            data={zipcodesGeoJSON}
            onClick={onZipSelect}
          />
      }
    </>
  );
};

export default ZipView;
