/* map showing several zip codes
 * featuredZip zipcode is highlighted and used to center view
 * receives: data array = [{codigo_postal, geometry GEOJSON {bbox, coordinates}}
 */
import React from "react";

import {
  MapContainer,
  GeoJSON,
  TileLayer,
  Tooltip,
  useMap,
  Circle,
  Marker,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";

/* patch to fix marker png not loading */
import L from "leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;
/* end of patch */

const ZipMap = ({
  featuredZip,
  data,
  circle,
  marker,
  onPolygonClick,
  onChangeBounds,
}) => {
  let zipMapDiv = <p>Mapa no disponible</p>;

  if (data === undefined || data.length === 0) {
    return zipMapDiv;
  }

  const MapCenter = ({ center, bounds }) => {
    const map = useMap();
    if (center.length > 0) {
      map.setView(center);
    }
    if (circle && circle.center && circle.radius) {
      const proyectionFactor = 132;
      map.fitBounds([
        [
          circle.center.lat - circle.radius / proyectionFactor,
          circle.center.lon - circle.radius / proyectionFactor,
        ],
        [
          circle.center.lat + circle.radius / proyectionFactor,
          circle.center.lon + circle.radius / proyectionFactor,
        ],
      ]);
    } else {
      map.fitBounds(bounds);
    }
    return null;
  };

  /*
  const MapBounds = (e) => {
    console.log(e)
    const bounds = e.target.getBounds();

    onChangeBounds ( [
      [bounds.getSouth(), bounds.getWest()],
      [bounds.getNorth(), bounds.getEast()],
    ]);
  };
*/

  let center = [19.43271, -99.13337]; //placeholder mexico Zocalo
  if (marker !== undefined && marker.length === 2) {
    center = marker;
  }

  let bounds = [];
  let polygonDivs = [];
  let markerDiv;
  let circleDiv;

  for (let i = 0; i < data.length; i++) {
    let style;
    let key;

    /* center view around target zipcode polygon */
    if (data[i].codigo_postal === featuredZip) {
      key = "__f" + data[i].codigo_postal;
      style = { color: "red" };
      center = [
        (data[i].geometry.bbox[1] + data[i].geometry.bbox[3]) / 2,
        (data[i].geometry.bbox[0] + data[i].geometry.bbox[2]) / 2,
      ];
      bounds = [
        [data[i].geometry.bbox[1], data[i].geometry.bbox[0]],
        [data[i].geometry.bbox[3], data[i].geometry.bbox[2]],
      ];
    } else {
      key = data[i].codigo_postal;
      style = { color: "grey", fillColor: "grey" };
    }

    const polygon_div = (
      <GeoJSON
        key={key}
        style={style}
        data={data[i].geometry}
        eventHandlers={{
          click: () => {
            onPolygonClick(data[i].codigo_postal);
          },
        }}
      >
        <Tooltip direction="top" offset={[0, 10]} opacity={1} permanent>
          {data[i].codigo_postal}
        </Tooltip>
      </GeoJSON>
    );

    /* ensure featuredZipcode is last in the list so appears in the front */
    if (data[i].codigo_postal === featuredZip) {
      polygonDivs.push(polygon_div);
    } else {
      polygonDivs.unshift(polygon_div);
    }
  } // for

  if (marker !== undefined && marker.length === 2) {
    markerDiv = <Marker position={marker} />;
  }

  if (circle && circle.radius && circle.center) {
    /* 1050 is meters per km for giving a buffer to the circle to ensure it displays covering
     * all zipcodes due to simplified map proyection precision
     */
    circleDiv = (
      <Circle
        pathOptions={{ color: "green" }}
        center={circle.center}
        radius={circle.radius * 1050}
      />
    );
  }

  if (
    (data !== undefined && data.length > 0) ||
    (marker !== undefined && marker.length === 2)
  ) {
    zipMapDiv = (
      <>
        <MapContainer
          style={{ height: "50vh" }}
          center={center}
          zoom={5}
          scrollWheelZoom={true}
          getBounds={onChangeBounds}
        >
          <MapCenter center={center} bounds={bounds} />
          {circleDiv}
          {markerDiv}

          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {polygonDivs}
          
        </MapContainer>
      </>
    );
  }

  return <>{zipMapDiv}</>;
};

export default ZipMap;
