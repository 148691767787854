import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navigation from './components/Navigation/Navigation';

import Home from './routes/Home';
import Explora from './routes/Explora';
import Encuentra from './routes/Encuentra';
import ZipPage from './routes/ZipPage';
import ZipRadius from './routes/ZipRadius';

const App = () => {
  const [activeItem, setActivePage] = useState("initialst");

  return (
    <div className="wrapper">
    
    <Router>
       <Navigation activeItem={activeItem} setActivePage={setActivePage}/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route  path="/explora/:estado?/:municipio?/:cp_or_geo?" component={Explora}/>
          <Route  path="/encuentra" component={Encuentra}/>
          <Route  path="/vercp/:zipcode" component={ZipPage}/>
          <Route  path="/cpradio" component={ZipRadius} />
        </Switch>
    </Router>
  </div>
  )
}

export default App;
