import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { LinkContainer } from "react-router-bootstrap";

const BreadcrumbExplore = ({ estado, municipio, cp_or_geo, zipcode }) => {
  const breadcrumbsJsx = [];

  breadcrumbsJsx.push(
    estado ? (
      <LinkContainer key={"country"} to="/explora">
        <Breadcrumb.Item>México</Breadcrumb.Item>
      </LinkContainer>
    ) : (
      <LinkContainer key={"country"} to="/explora">
        <Breadcrumb.Item active>México</Breadcrumb.Item>
      </LinkContainer>
    )
  );

  if (estado) {
    breadcrumbsJsx.push(
      municipio ? (
        <LinkContainer key={estado} to={"/explora/" + estado}>
          <Breadcrumb.Item>{estado}</Breadcrumb.Item>
        </LinkContainer>
      ) : (
        <LinkContainer key={estado} to={"/explora/" + estado}>
          <Breadcrumb.Item active>{estado}</Breadcrumb.Item>
        </LinkContainer>
      )
    );
  }

  if (municipio) {
    breadcrumbsJsx.push(
      zipcode ? (
        <LinkContainer
          key={"__cp" + estado + municipio}
          to={"/explora/" + estado + "/" + municipio + "/" + cp_or_geo}
        >
          <Breadcrumb.Item>{cp_or_geo + ": " + municipio}</Breadcrumb.Item>
        </LinkContainer>
      ) : (
        <LinkContainer
          key={"__cp" + estado + municipio}
          to={"/explora/" + estado + "/" + municipio + "/" + cp_or_geo}
        >
          <Breadcrumb.Item active>
            {cp_or_geo + ": " + municipio}
          </Breadcrumb.Item>
        </LinkContainer>
      )
    );
  }

  if (zipcode) {
    breadcrumbsJsx.push(
      <LinkContainer
        key={zipcode}
        to={"/explora/" + estado + "/" + municipio + "/cp/" + zipcode}
      >
        <Breadcrumb.Item active>{zipcode}</Breadcrumb.Item>
      </LinkContainer>
    );
  }

  return (
    <>
      <Breadcrumb>{breadcrumbsJsx}</Breadcrumb>
    </>
  );
};

export default BreadcrumbExplore;
