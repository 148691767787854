import React from "react";
import { useParams } from "react-router-dom";

import "../assets/App.css";
import ZipDisplay from "../components/ZipDisplay/ZipDisplay";
import Footer from "../components/Footer/Footer";

const ZipPage = () => {
  const { zipcode } = useParams();

  return (
    <>
      <div className="App__mainContainer">
        <ZipDisplay zipcode={zipcode} dynamicLoading={true} />
      </div>
    </>
  );
};

export default ZipPage;
