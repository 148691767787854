import React from "react";

import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./Navigation.css";

const Navigation = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Navbar.Brand>geodatosmexico.com</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <LinkContainer to="/">
              <Nav.Link>Busca</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/explora">
              <Nav.Link>Explora Mex</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/encuentra">
              <Nav.Link>Localiza Cod Postal</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/cpradio">
              <Nav.Link>Radio Cod Postal</Nav.Link>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Navigation;
