import React from 'react';
import { Spinner } from 'react-bootstrap';
import './LoadingSpinner.css';

const LoadingSpinner = () => {
  return (
    <>
        <Spinner className="LoadSpinner__spinner" animation="border" role="status">
           <span className="sr-only">Cargando...</span>
        </Spinner>
    </>
  );
}

export default LoadingSpinner;