import React from "react";
import { Navbar } from "react-bootstrap";

const Footer = () => {
  return (
    <>
        <Navbar fixed="bottom">
        <   Navbar.Brand >&copy; geodatosmexico 2021</Navbar.Brand>
        <p>contacto@geodatosmexico.com</p>
        </Navbar>

    </>
  );
};

export default Footer;
