import React, { useEffect, useState } from "react";

import LocateCoordsForm from '../components/LocateCoordsForm';

import ZipDisplay from '../components/ZipDisplay/ZipDisplay'

import '../assets/App.css'

const Encuentra = () => {
  const [zipcode, setZipCode] = useState();
  const [coords, setCoords] = useState({lat : null, lon : null});
  const [zipJSX, setZipJSX] = useState();

  useEffect(() => {
    if (zipcode) {
      setZipJSX(<ZipDisplay zipcode={zipcode} lat={coords.lat} lon={coords.lon} />)
    }
  }, [zipcode, coords]);

  const setSearchHandler = (e) => {    
    if (e.isFound) {
      setZipCode(e.searchResults[0].codigo_postal);
      setCoords({lat : e.lat, lon : e.lon });
    }
  }

  return (
    <>
      <div className="App__mainContainer">
        <LocateCoordsForm onSearch={setSearchHandler} />
        {zipJSX}
      </div>
      
    </>
  );
};

export default Encuentra;
