import React, { useEffect, useState } from "react";

import { Form } from "react-bootstrap";

import ZipDisplay from "../components/ZipDisplay/ZipDisplay";
import SearchTownForm from "../components/SearchTownForm/SearchTownForm";

import "../assets/App.css";

const axios = require("axios");
const API_BASE_URL = require("../api-home.js").api;
const DEFAULT_RADIUS = 1.5;

const ZipRadius = () => {
  const [centerZipObj, setCenterZipObj] = useState();
  const [centerCoords, setCenterCoords] = useState();
  const [radius, setRadius] = useState(DEFAULT_RADIUS);
  const [zipJSX, setZipJSX] = useState();

  useEffect(() => {
    if (centerZipObj && centerZipObj.length > 0) {
      setZipJSX(
        <ZipDisplay
          key={`z${centerZipObj[0].zip}r${radius ? radius : ""}`}
          zipcode={centerZipObj[0].zip}
          isRadius={true}
          radius={radius}
          radiusCenter={centerCoords}
          loadNeighborsOnce={true}
        />
      );
    }
  }, [centerZipObj, centerCoords, radius]);

  useEffect(() => {
    if (centerZipObj && centerZipObj.length > 0) {
      axios
        .get(`${API_BASE_URL}/encuentra/${centerZipObj[0].zip}/centro`)
        .then((res) => {
          if (res.data.length > 0) {
            setCenterCoords({
              lat: res.data[0].centroid.y,
              lon: res.data[0].centroid.x,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [centerZipObj, radius]);

  const radiusSelectionHandler = (e) => {
    setRadius(e.target.value);
  };

  return (
    <>
      <div className="App__mainContainer">
        <h1 className="App__pageheader">Radio Código Postal</h1>
        <div className="App__infotext">
          <p>
            Búsqueda de códigos postales en México cercanos a otro código
            postal, se muestran los códigos postales dentro del radio
            especificado. Selecciona un código postal para usar como centro y
            una distancia para visualizar los códigos postales cercanos dentro de ese
            radio. Nota: las distancias son aproximadas.
          </p>
        </div>

        <Form>
          <Form.Group controlId="radiusform.exampleForm.ControlInput1">
            <Form.Label>Centrar en : </Form.Label>
            <SearchTownForm setSelected={setCenterZipObj} />
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label>Buscar CP en un radio de: </Form.Label>
            <Form.Control
              as="select"
              onChange={radiusSelectionHandler}
              defaultValue={DEFAULT_RADIUS}
            >
              <option value="1">1 km</option>
              <option value="1.5">1.5 km</option>
              <option value="2">2 km</option>
              <option value="3">3 km</option>
              <option value="5">5 km</option>
            </Form.Control>
          </Form.Group>
        </Form>
        {zipJSX}
      </div>
    </>
  );
};

export default ZipRadius;
