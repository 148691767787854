import React from 'react';
import { Button } from 'react-bootstrap';

import './LinkGrid.css';

const LinkGrid = ({ header, data, onClick }) => {
  if (data === undefined) {
    return <></>;
  }
  
  const header_txt = header === undefined ? "" : header;
  const linkDivs = data.map((d, i) => 
    <Button 
      key={i} 
      
      variant="link" 
      onClick={() => onClick(d.codigo_postal)}
    ><span className="LinkGrid__link" >{d.codigo_postal}</span>
    </Button>
  );

  return (
    <>
      <div className="LinkGrid__container">
        <div className="LinkGrid__header">{header_txt}</div>
        <div className="LinkGrid__data">{linkDivs}</div>
      </div>
    </>
  );
};

export default LinkGrid;