import React, { useEffect, useState } from "react";
import { AsyncTypeahead, ClearButton } from "react-bootstrap-typeahead";
import { Spinner } from "react-bootstrap";
import "./SearchTownForm.css";

const axios = require("axios");
const API_BASE_URL = require("../../api-home.js").api;

const SearchTownForm = ({ setSelected }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [optionsAsentamiento, setOptionsAsentamiento] = useState([]);

  const [inputType, setInputType] = useState("text");
  const [inputText, setInputText] = useState("");
  const [urlOptions, setUrlOptions] = useState();
  const [minLength, setMinLength] = useState(3);

  const inputChangeHandler = (e) => {
    if (e.match(/\d+/)) {
      setInputType("zipcode");
    } else {
      setInputType("text");
    }
  };

  const searchHandler = (userInputText) => {
    setInputText(userInputText);
  };

  const changeHandler = (selected) => {
    setSelected(selected);
  };

  useEffect(() => {
    if (inputType === "zipcode") {
      setMinLength(5);
    } else {
      setMinLength(3);
    }
  }, [inputType]);

  useEffect(() => {
    if (inputType === "zipcode") {
      setUrlOptions(API_BASE_URL + "/encuentra/asentabyzip?key=" + inputText);
    } else {
      setUrlOptions(API_BASE_URL + "/encuentra/asentabytxt?key=" + inputText);
    }
  }, [inputText, inputType]);

  useEffect(() => {
    if (urlOptions !== undefined) {
      /* use timeout to debounce requests 
       this is called after 500 milliseconds from last keystroke */

      const identifier = setTimeout(() => {
        setIsLoading(true);
        axios
          .get(urlOptions)
          .then((res) => {
            if (res.data !== undefined) {
              const options = res.data.map((i) => ({
                zip: i.codigo_postal,
                zipPath:
                  "/explora/" +
                  i.estado +
                  "/" +
                  i.municipio +
                  "/cp/" +
                  i.codigo_postal,
                asentamiento:
                  i.codigo_postal +
                  " | " +
                  i.asentamiento +
                  "," +
                  i.municipio +
                  "," +
                  i.estado,
              }));
              setOptionsAsentamiento(options);
            }
            setIsLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 500);
      return () => {
        /*cleanup function (runs on unmount or before new side effect execution)
         *clear the timer every time stroke
         */
        clearTimeout(identifier);
      };
    } // if
  }, [urlOptions]);

  const filterBy = () => true;

  return (
    <>
      <AsyncTypeahead
        className="SearchTownForm__typeahead"
        filterBy={filterBy}
        id="async-example"
        isLoading={isLoading}
        labelKey="asentamiento" /* must match options key */
        minLength={minLength}
        onSearch={searchHandler}
        options={optionsAsentamiento}
        placeholder="Busca por Código Postal o Población ..."
        onChange={changeHandler}
        onInputChange={inputChangeHandler}
      >
        {({ onClear, selected }) => (
          <div className="rbt-aux">
            {!!selected.length && (
              <ClearButton className="toggle-button" onClick={onClear} />
            )}
            {isLoading && <Spinner animation="grow" size="sm" />}
          </div>
        )}
      </AsyncTypeahead>
    </>
  );
};

export default SearchTownForm;
