/* build a data table from a list of objects, if link array is defined will include a link
 * in the corresponding cell.
 * links must match headers, if no headers are defined will use data keys as headers
 */

import React from "react";
import { Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./DataTable.css";

const DataTable = ({ headers, data, links }) => {
  if (!data || data.length === 0) return <></>;

  const data_headers = headers ? headers : Object.keys(data[0]);

  const headerJsx = data_headers.map((h, i) => (
    <th className="DataTable__headers" key={i}>
      <span className="DataTable__headers_txt">{h}</span>
    </th>
  ));

  const dataJsx = data.map((row, j) => {
    let rowVals = Object.values(row);
    let rowData = rowVals.map((col, i) => {
      if (Array.isArray(col)) {
        col = col.map((s, i) => (i < col.length - 1 ? s + ", " : s));
      }
      if (links && typeof links[j] !== "undefined") {
        if (links[j][data_headers[i]] !== undefined) {
          return (
            <td key={i}>
              <Link to={links[j][data_headers[i]]}>
                <Button className="DataTable__button" variant="outline-success">
                  {col}
                </Button>
              </Link>
            </td>
          );
        }
      }
      return <td key={i}>{col}</td>;
    });
    return <tr key={j}>{rowData}</tr>;
  });

  return (
    <Table striped bordered hover>
      <thead>
        <tr>{headerJsx}</tr>
      </thead>
      <tbody>{dataJsx}</tbody>
    </Table>
  );
};

export default DataTable;
