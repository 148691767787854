import React from "react";
import "./ZipCard.css";

const ZipCard = ({ zipObj }) => {
  if (zipObj === undefined) {
    return <></>;
  }

  const asentaJsx = zipObj.Asentamientos.map((d, i) =>
    i < zipObj.Asentamientos.length - 1 ? (
      <span key={i}>{d + ", "}</span>
    ) : (
      <span key={i}>{d + "."}</span>
    )
  );

  return (
    <>
      <div className="ZipCard__container">
        <div className="ZipCard_zipcode_txtarea">
          <div className="ZipCard__zipcode">
            <span className="ZipCard__header">Código Postal : </span>
            <span className="ZipCard__zipcodetxt">{zipObj.codigo_postal}</span>
          </div>

          <div className="ZipCard__data">
            <span className="ZipCard__header"> Estado : </span>
            <span className="ZipCard__data_item">{zipObj.Estado}</span>
          </div>

          <div className="ZipCard__data">
            <span className="ZipCard__header"> Ciudad : </span>
            <span className="ZipCard__data_item">{zipObj.Ciudad}</span>
          </div>

          <div className="ZipCard__data">
            <span className="ZipCard__header"> Municipio : </span>
            <span className="ZipCard__data_item">{zipObj.Municipio}</span>
          </div>

          <div className="ZipCard__data">
            <span className="ZipCard__header"> Asentamientos : </span>
            <span className="ZipCard__asentamientos">{asentaJsx}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ZipCard;
