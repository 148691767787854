import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataTable from "../components/DataTable/DataTable";
import BreadcrumbExplore from "../components/BreadcrumbExplore";
import { Spinner } from "react-bootstrap";



import "../assets/Explora.css";
import "../assets/App.css";

const axios = require("axios");
const API_BASE_URL = require("../api-home.js").api;

function Explora() {
  const params = useParams();
  const [summaryData, setSummaryData] = useState([]);
  const [entityLink, setEntityLink] = useState([]);
  const [loadingSpinner, setLoadingSpinner] = useState();
  const [nowDisplaying, setNowDisplaying] = useState({ params: [] });

  /* decide table to display based on params */
  useEffect(() => {
    let urlToLoadData;

    let depth;

    if (params.estado === undefined) {
      depth = "mexico";
      urlToLoadData = API_BASE_URL + "/mexico";
    } else if (params.municipio === undefined) {
      depth = "estado";
      urlToLoadData = API_BASE_URL + "/mexico/" + params.estado;
    } else if (params.cp_or_geo === "cp") {
      depth = "cp";
      urlToLoadData =
      API_BASE_URL + "/mexico/" + params.estado + "/" + params.municipio + "/cp";
    } else if (params.cp_or_geo === "geo") {
      depth = "geo";
      urlToLoadData =
      API_BASE_URL + "/mexico/" + params.estado + "/" + params.municipio + "/geo";
    }

    setNowDisplaying({ depth, urlToLoadData, params: params });
  }, [params]);

  /* fetch data and setup links in table */
  useEffect(() => {
    if (!nowDisplaying.urlToLoadData) {
      return;
    }

    setLoadingSpinner(
      <Spinner animation="border" role="status" size="sm">
        <span className="sr-only">Cargando...</span>
      </Spinner>
    );
    axios
      .get(nowDisplaying.urlToLoadData)
      .then((res) => {
        let dataToDisplay;
        switch (nowDisplaying.depth) {
          case "mexico":
            dataToDisplay = res.data.map((d) => ({
              Estado: d.entidad,
              "Principales poblaciones": d.principales_locaciones,
              Población: d.POBTOT.toLocaleString(),
              Mx100H: d.Mujeres_c_100Hombres,
              "%Viv Sin Bienes": d.Porc_viv_sinbienes,
            }));
            setSummaryData(dataToDisplay);
            setEntityLink(
              dataToDisplay.map((d) => {
                return { Estado: "/explora/" + d.Estado };
              })
            );
            break;

          case "estado":
            dataToDisplay = res.data.map((d) => ({
              Municipio: d.entidad,
              CP: d.codigos_postales,
              Ciudades: d.ciudades,
              GeoData: d.geo_poblaciones,
              Población: d.POBTOT.toLocaleString(),
              Mx100H: Math.round(d["F/H"] * 100),
              "%Viv Sin Bienes": d.Porc_viv_sinbienes,
              "%Viv Desocupada": d.Porc_Viv_Desocupada,
            }));
            setSummaryData(dataToDisplay);
            setEntityLink(
              dataToDisplay.map((d) => ({
                CP:
                  "/explora/" +
                  nowDisplaying.params.estado +
                  "/" +
                  d.Municipio +
                  "/cp",
                GeoData:
                  "/explora/" +
                  nowDisplaying.params.estado +
                  "/" +
                  d.Municipio +
                  "/geo",
              }))
            );
            break;

          case "cp":
            dataToDisplay = res.data.map((d) => {
              return {
                CP: d.codigo_postal,
                Asentamientos: d.asentamientos,
                Mapa: d.mapa !== "" ? "Disponible" : "--",
              };
            });
            setSummaryData(dataToDisplay);
            setEntityLink(
              dataToDisplay.map((d) => {
                return {
                  CP: "/vercp/" + d.CP,
                };
              })
            );
            break;

          case "geo":
            dataToDisplay = res.data.map((d) => {
              return {
                Locación: d.locacion,
                Población: d.POBTOT.toLocaleString(),
                Mx100H: d["F/H"] ? Math.round(d["F/H"] * 100) : "",
                "%Viv Sin Bienes": d.Porc_viv_sinbienes,
                "%Viv Desocupada": d.Porc_Viv_Desocupada,
              };
            });
            setSummaryData(dataToDisplay);
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoadingSpinner();
      });
  }, [nowDisplaying]);

  return (
    <div className="App__mainContainer">
      <div className="ZipView__breadcrumb">
        <BreadcrumbExplore
          estado={nowDisplaying.params.estado}
          municipio={nowDisplaying.params.municipio}
          cp_or_geo={nowDisplaying.params.cp_or_geo}
        />
      </div>
      <DataTable data={summaryData} links={entityLink} />
      {loadingSpinner}
    </div>
  );
}

export default Explora;
