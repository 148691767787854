import React, { useEffect, useState } from "react";
import SearchTownForm from "../components/SearchTownForm/SearchTownForm";
import { useHistory, Link } from "react-router-dom";

import Footer from "../components/Footer/Footer";
import LinkGrid from "../components/LinkGrid/LinkGrid";

import "../assets/App.css";
import "../assets/Home.css";

const estados = [
  "Aguascalientes",
  "Baja California",
  "Baja California Sur",
  "Campeche",
  "Chiapas",
  "Chihuahua",
  "Ciudad de México",
  "Coahuila de Zaragoza",
  "Colima",
  "Durango",
  "Guanajuato",
  "Guerrero",
  "Hidalgo",
  "Jalisco",
  "México",
  "Michoacán de Ocampo",
  "Morelos",
  "Nayarit",
  "Nuevo León",
  "Oaxaca",
  "Puebla",
  "Querétaro",
  "Quintana Roo",
  "San Luis Potosí",
  "Sinaloa",
  "Sonora",
  "Tabasco",
  "Tamaulipas",
  "Tlaxcala",
  "Veracruz de Ignacio de la Llave",
  "Yucatán",
  "Zacatecas",
];

const axios = require("axios");
const API_BASE_URL = require("../api-home.js").api;

const Home = () => {
  let history = useHistory();
  const [mostWanted, setMostWanted] = useState();

  const estadosJSX = estados.map((e, i) => (
    <Link className="Home__estados_link" key={i} to={`explora/${e}`}>
      {e}
    </Link>
  ));

  const selectedHandler = (newSelection) => {
    if (newSelection && newSelection.length === 1) {
      history.push("/vercp/" + newSelection[0].zip);
    }
  };

  useEffect(() => {
    axios
        .get(`${API_BASE_URL}/encuentra/masbuscados`)
        .then((res) => {
            setMostWanted(res.data);
        })
        .catch((err) => {
            console.log(err);
        })
  }, []);

  const zipGridHandler = (zip) => {
    history.push("/vercp/" + zip);
  }

  return (
    <>
      <div className="App__mainContainer">
        <h1 className="App__pageheader">Busca Código Postal</h1>
        <div className="App__infotext">
          <p>
            Búsqueda de códigos postales en México, busca utilizando el número
            del código postal o buscando algun asentamiento o población. Si no
            encuentras el que buscas puedes{" "}
            <Link to="/explora">explorar la base de datos.</Link> También puedes
            localizar el código postal con tu{" "}
            <Link to="/encuentra"> ubicación o proporcionando coordenadas</Link>
            . Buscar códigos dentro de un{" "}
            <Link to="/cpradio">radio de distancia</Link> a otro código.
          </p>
        </div>
        <SearchTownForm setSelected={selectedHandler} />

        <div className="Home__estados_container">
          <div className="Home__estados_header">Estados</div>
          <div className="Home__estados_data"> {estadosJSX}</div>
        </div>

        <LinkGrid header="Códigos Postales más buscados" data={mostWanted} onClick={zipGridHandler}/>

        <div className="App__lastshow">
          <p>
            Tomamos nuestros datos de{" "}
            <a href="https://datos.gob.mx/">datos.gob.mx</a>
          </p>
        </div>
      </div>


      <Footer />
    </>
  );
};

export default Home;
