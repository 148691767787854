import React, { useState, useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router";

import ZipView from "../ZipView/ZipView";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import "../../assets/App.css";
import "../../assets/Explora.css";
import "../ZipView/ZipView.css";

const axios = require("axios");
const API_BASE_URL = require("../../api-home.js").api;

const ZipDisplay = ({
  zipcode,
  lat,
  lon,
  isRadius,
  radius,
  radiusCenter,
  loadNeighborsOnce,
  dynamicLoading,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  let history = useHistory();

  const [zipObj, setZipObj] = useState(); //zipcode data loaded from db
  const [zipsOnDisplay, setZipsOnDisplay] = useState();
  const [zipView, setZipView] = useState();
  const [error, setError] = useState(null);

  const [currentZip, setCurrentZip] = useState(zipcode);

  const [isNewCenter, setIsNewCenter] = useState(true); // used to avoid reload on radius setup

  useEffect(() => {
    setCurrentZip(zipcode);
  }, [zipcode]);

  /* when a zipcode is provided look for its data in the db
   * if not in db zipObj === undefined
   */
  useEffect(() => {
    if (currentZip) {
      setIsLoading(true);
      axios
        .get(`${API_BASE_URL}/encuentra/cp/${currentZip}`)
        .then((res) => {
          if (res.data.length === 0) {
            setZipObj(undefined);
            throw new Error("Lo sentimos, no disponemos de datos para ese código postal");
          }
          setZipObj(res.data[0]);
          setError(null);
        })
        .catch((err) => {
          setError(<Alert variant="info">{err.message}</Alert>);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentZip]);

  /* fetch other zipcodes to display */
  useEffect(() => {
    if (!isNewCenter) {
      return;
    }

    if (zipObj) {
      let urlFetch;
      if (isRadius) {
        urlFetch = `${API_BASE_URL}/encuentra/${zipObj.codigo_postal}/vecinos/${radius}`;
      } else {
        urlFetch = `${API_BASE_URL}/encuentra/${zipObj.codigo_postal}/vecinos`;
      }

      setIsLoading(true);
      axios
        .get(urlFetch)
        .then((res) => {
          if (res.data.length > 0) {
            setZipsOnDisplay(res.data);
            if (loadNeighborsOnce) {
              setIsNewCenter(false); //load only once in radius mode
            }
          }
          setError(null);
        })
        .catch((err) => {
          setError(<Alert variant="danger">Error: {err.message}</Alert>);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setZipsOnDisplay();
    }
  }, [zipObj, isRadius, radius, isNewCenter, loadNeighborsOnce]);

  /* build view */
  useEffect(() => {
    if (zipObj) {
      setZipView(
        <>
          {
            <ZipView
              featuredZipcode={zipObj}
              zipcodesGeoJSON={zipsOnDisplay}
              lat={lat}
              lon={lon}
              onZipSelect={
                dynamicLoading
                  ? (z) => history.push("/vercp/" + z)
                  : (z) => setCurrentZip(z)
              }
              radius={radius}
              radiusCenter={radiusCenter}
            />
          }
        </>
      );
    }
  }, [
    zipsOnDisplay,
    zipObj,
    lat,
    lon,
    radius,
    radiusCenter,
    history,
    dynamicLoading,
  ]);

  return (
    <>
      {!isLoading && error}
      {isLoading && <LoadingSpinner />}
      <div className="ZipView__zipview">{zipView}</div>
    </>
  );
};

export default ZipDisplay;
